export var project = {
  id: null,
  name: null,
  type: null,
  ticker: null,
  stage: null,
  description: null,
  shortDescription: null,
  homepage: null,
  whitepaperUrl: null,
  youTubeEmbedId: null,
  twitterHandle: null,
  telegramHandle: null,
  youtubeHandle: null,
  facebookHandle: null,
  discordHandle: null,
  githubLink: null,
  imageUrl: null,
  ownerEmail: null,
  tokenType: null,
  totalSupply: null,
  circulatingSupply: null,
  tokenDistributionLink: null,
  saleDetailsLink: null,
  createdDate: null,
  updatedDate: null,
  verified: false,
  upcomingSale: null,
  saleStartDate: new Date(),
  saleEndDate: null,
  saleDetailLink: null,
  saleTokenPrice: null,
  tokenDistributionDetail: null,
  acceptedFunding: null,
  teamDescription: null,
  projectTeam: [],
};
